@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";
@import "~@ng-select/ng-select/themes/material.theme.css";


@media print {
  mat-toolbar {
    &.main-header {
      display: none;
    }
  }

  mat-sidenav.sidebar-panel {
    display: none;
  }

  mat-card {
    box-shadow: none !important;
    border: none !important;
  }

  mat-sidenav-content {
    margin-left: 0!important;
  }

  body {
    background-color: white;
  }
}
