@use 'node_modules/@angular/material' as mat;
@include mat.core();
@import "src/assets/styles/scss/material.variables";

.media-grid {
  text-align: center;
  overflow: hidden;

  figure {
    position: relative;
    text-align: center;
    cursor: pointer;
    background: rgba(0, 0, 0, .38);
    text-align: left;
    border-radius: $border-radius-base $border-radius-base 0 0;
    text-align: center;
  }

  figure img {
    position: relative;
    display: block;
    max-width: 100%;
    border-radius: $border-radius-base $border-radius-base 0 0;
  }

  &.compact {
    border-radius: $border-radius-base 0 0 $border-radius-base;

    figure {
      border-radius: $border-radius-base 0 0 $border-radius-base;
    }

    figure img {
      border-radius: $border-radius-base 0 0 $border-radius-base;
    }
  }

  .product-badge {
    color: #fff;
    text-align: center;
    position: absolute;
    border-top: 30px solid mat.get-color-from-palette($warn);
    border-bottom: 30px solid transparent;
    border-right: 30px solid transparent;
    border-left: 30px solid mat.get-color-from-palette($warn);
    top: 0;
    left: 0;
    z-index: 100;

    span {
      position: absolute;
      transform: rotate(-45deg);
      top: -18px;
      left: -25px;
      white-space: nowrap;
    }
  }

  figure figcaption {
    color: #fff;
    text-transform: uppercase;
    backface-visibility: hidden;
  }

  figure figcaption::before {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-color: rgba(0, 0, 0, .38);
    content: '';
    transition: transform $transition-duration;
  }

  figure figcaption::before {
    right: 0;
    bottom: 0;
    border-width: 0 45px 0 0;
    transform: translate3d(45px, 0, 0);
  }

  figure figcaption,
  figure figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  figure figcaption>a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
  }

  figure p {
    margin: 0;
  }

  figure p {
    float: right;
    clear: both;
    text-align: center;
    text-transform: none;
    font-size: 1rem;
    width: 45px;
    padding-top: 1rem;
  }

  figure img,
  figure p a {
    transition: opacity $transition-duration, transform $transition-duration;
  }

  figure img {
    opacity: 0.85;
  }

  figure p a {
    display: block;
    margin-bottom: $spacer;
    color: #fff;
    opacity: 0;
    transform: translate3d(90px, 0, 0);
  }

  figure:hover figcaption::before {
    transform: translate3d(0, 0, 0);
  }

  figure:hover p a {
    transform: translate3d(0, 0, 0);
  }

  figure:hover p a {
    opacity: 1;
  }

  figure:hover p a:first-child {
    transition-delay: 0.025s;
  }

  figure:hover p a:nth-child(2) {
    transition-delay: 0.05s;
  }

  figure:hover p a:nth-child(3) {
    transition-delay: 0.075s;
  }

  figure:hover p a:nth-child(4) {
    transition-delay: 0.1s;
  }
}
