@use 'node_modules/@angular/material' as mat;
@include mat.core();
@import "src/assets/styles/scss/material.variables";
/* $typography
 ------------------------------------------*/
a {
  text-decoration: none;
  color: inherit;

  @include hover-focus {
    cursor: pointer;
    text-decoration: none;
    outline: 0;
  }
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid mat.get-color-from-palette($foreground, divider);
}

strong,
b {
  font-weight: 700;
}

.strikethrough {
  text-decoration: line-through;
}
